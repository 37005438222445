import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'mobile-device-detect';
import { Avatar, Button as MaterialButton, Chip } from '@material-ui/core';
import { Button, Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import Rating from 'react-rating';
import axios from 'axios';
import Hashids from 'hashids/cjs';
import ModalView from '../../components/ModalView';
import { language, messages } from '../../utils/localeUtils';
import LoadingIndicator from '../../components/LoadingIndicator';
import Icon from '../../components/Icon';
import MediaCropper from '../../components/cropper';
import { config } from '../../settings';
import ErrorLogger from '../../utils/errorLogger';
import ModalDialog from '../../components/ModalDialog';
import { VenueStore } from '../../stores';
import { addOrMergeCheckin } from './actions';
import MediaGrid from '../../components/media-grid';
import FileUploader from '../../components/file-uploader';
import UserSearch from '../../components/user-search';
import CigarSearch from '../../components/cigar-search';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Cigar from '../../models/Cigar';
import EditorDiscardConfirmation from '../../components/editor-discard-confirmation';
import {
  EmptySquareStar,
  EmptySquareStarLarge,
  FilledSquareStar,
  FilledSquareStarLarge,
} from '../../components/Ratings';
const hashids = new Hashids('', 12);

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const defaults = {
  submittedId: null,
  checkinAvgRating: null,
  checkinSelectionRating: null,
  checkinServiceRating: null,
  checkinAtmosphereRating: null,
  checkinPriceRating: null,
  checkinImageThumbnails: [],
  selectedMedia: [],
  selectedImageIndex: null,
  selectedImageFile: null,
  selectedVideoFile: null,
  comment: null,
};

let saveDisabled = false;

export function CheckinEditor(props) {
  const initialValues = { ...defaults };
  if (props.location && props.location.state) {
    const { checkin } = props.location.state;
    if (checkin) {
      console.log('Got checkin from props...');
      console.log(checkin);
      if (checkin.advance_rating) {
        initialValues.checkinAvgRating = checkin.advance_rating.rating;
        initialValues.checkinSelectionRating = checkin.advance_rating.selection;
        initialValues.checkinServiceRating = checkin.advance_rating.service;
        initialValues.checkinAtmosphereRating = checkin.advance_rating.atmosphere;
        initialValues.checkinPriceRating = checkin.advance_rating.price;
      }
      initialValues.comment = checkin.comment;
      initialValues.checkinImageThumbnails = checkin.media && checkin.media.map((file) => ({ ...file, src: file.media_url, type: file.media_type }));
    }
  }
  const [{
    checkinAvgRating,
    checkinSelectionRating,
    checkinServiceRating,
    checkinAtmosphereRating,
    checkinPriceRating,
    selectedMedia,
    selectedImageFile,
    selectedVideoFile,
    selectedTaggedUsers,
    comment,
  }, setState] = useState(initialValues);
  const [submittedId, setSubmittedId] = useState(initialValues.submittedId);
  const [initialized, setInitialized] = useState(false);
  const [showMediaOptionsSheet, setShowMediaOptionsSheet] = useState(false);
  const [removeCheckinMedia, setRemoveCheckinMedia] = useState([]);
  const [checkinMediaThumbnails, setCheckinMediaThumbnails] = useState(initialValues.checkinImageThumbnails);
  const [showMediaCropper, setShowMediaCropper] = useState(false);
  const [needsSubmitted, setNeedsSubmitted] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [mediaUploadStatus, setMediaUploadStatus] = useState({}); // Used to determine when all media has been uploaded
  const [showAdvancedRatingModal, setShowAdvancedRatingModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showCigarModal, setShowCigarModal] = useState(false);
  const [selectedCigar, setSelectedCigar] = useState(null);
  const [showTaggedUsersModal, setShowTaggedUsersModal] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState(null);
  const [showExtra, setShowExtra] = useState(false);

  const addMedia = useRef(null);
  const addVideo = useRef(null);
  let initialLoad = true;

  const calculateAverageRating = () => {
    let totalRating = 0;

    totalRating += checkinSelectionRating;
    totalRating += checkinServiceRating;
    totalRating += checkinAtmosphereRating;
    totalRating += checkinPriceRating;

    setState((prevState) => ({
      ...prevState,
      checkinAvgRating: (totalRating / 4),
    }));
  };

  useEffect(() => {
    console.log(`Uploading is ${uploadingMedia ? 'true' : 'false'}`);
    console.log(`Needs submitted is ${needsSubmitted ? 'true' : 'false'}`);
    if (needsSubmitted && !uploadingMedia) {
      console.log('Finished uploading');
      setUploadingMedia(false);
      console.log('Auto saving checkin from media callback');
      onSave();
    }
  }, [uploadingMedia, needsSubmitted]);

  useEffect(() => {
    // FIXME Only do this when user clicks next? Or should 'back' also save the rating?
    // if () {
    calculateAverageRating();
    // }
  }, [
    props.venue,
    checkinSelectionRating,
    checkinServiceRating,
    checkinAtmosphereRating,
    checkinPriceRating,
  ]);

  const addCigar = () => {
    setShowCigarModal(true);
    setShowTaggedUsersModal(false);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const addFriends = () => {
    setShowCigarModal(false);
    setShowTaggedUsersModal(true);
    if (!isMobile) {
      setShowExtra(true);
    }
  };

  const renderCheckInModalContent = () => (
    <div>
      <h4>How was your experience?</h4>
      { isMobile
        ? (
          <div style={{ textAlign: 'center', marginBottom: 10 }}>
            <Rating
              initialRating={checkinAvgRating}
              emptySymbol={<EmptySquareStarLarge />}
              fullSymbol={<FilledSquareStarLarge />}
              fractions={2}
              style={{ color: 'rgb(214, 194, 144)', fontSize: 28 }}
              onChange={(value) => {
                console.log('Selected rating:');
                console.log(value);
                setState((prevState) => ({
                  ...prevState,
                  checkinAvgRating: value,
                  checkinSelectionRating: value,
                  checkinServiceRating: value,
                  checkinAtmosphereRating: value,
                  checkinPriceRating: value,
                }));
                setShowAdvancedRatingModal(true);
              }}
            />
          </div>
        ) : renderAdvancedRatingEntry() }
      <textarea
        value={comment}
        onChange={(event) => {
          event.persist();
          console.log(event);
          setState((prevState) => ({
            ...prevState,
            comment: event.target.value,
          }));
        }}
        className="form-control"
        placeholder="Describe your visit..."
        style={{ height: 120 }}
      />
      <div
        style={{
          marginBottom: 10,
          paddingBottom: 10,
        }}
      />
      {taggedUsers && renderTaggedUsers()}
      {selectedCigar && renderCigar()}
      {(checkinMediaThumbnails && checkinMediaThumbnails.length > 0) && (
        <div className="bottom-divider selected-checkin-media" style={{ marginTop: 16, marginBottom: 10, paddingBottom: 10 }}>
          <MediaGrid
            media={checkinMediaThumbnails}
            thumbnailMedia={checkinMediaThumbnails.map((media) => ({
              ...media,
              // INFO src can be either base64 or a URL
              // FIXME Only resize images? How to handle video...?
              src: media.type === 'image' ? media.src.replace('/checkins/', '/checkins/500x500/') : media.src,
            }))}
            direction="vertical"
            fancyBox
            withEditor
            onChange={(images) => {
              console.log('Updating images...');
              setState((prevState) => ({
                ...prevState,
                selectedMedia: images,
              }));
              setCheckinMediaThumbnails(images);
            }}
            onEdit={(index, image) => {
              setState((prevState) => ({
                ...prevState,
                selectedImageFile: image,
                selectedImageIndex: index || 0, // INFO Patch for rare case when the first index won't return a value
              }));
              setShowMediaCropper(true);
            }}
            onRemoved={(file) => {
              console.log('Removed file:');
              console.log(file);
              if (file && file.id) {
                setRemoveCheckinMedia([...removeCheckinMedia, file]);
              }
            }}
          />
        </div>
      )}
      <div>
        <FileUploader
          id="image-picker"
          multiple
          accept="image/*"
          forwardedRef={addMedia}
          files={checkinMediaThumbnails}
          folder="sessions"
          filenameTemplate={() => {
            const userHash = hashids.encode(props.auth.user.id);
            const venueHash = hashids.encode(props.venue ? props.venue.id : new Date().getTime());
            return `${userHash}-${venueHash}-uuid`;
          }}
          uploading={(isUploading) => {
            setUploadingMedia(isUploading);
          }}
          onUploading={(uploadingFile, files) => {
            // console.log('Uploading file:');
            // console.log(uploadingFile);
            // console.log('Uploading files:');
            // console.log(files);
            setState((prevState) => ({
              ...prevState,
              selectedMedia: files,
            }));
            setCheckinMediaThumbnails(files);
          }}
          onUploaded={(uploadedFile, files) => {
            // console.log('Uploaded files:');
            // console.log(files);
            setState((prevState) => ({
              ...prevState,
              selectedMedia: files,
            }));
            setCheckinMediaThumbnails(files);
          }}
        />
        <FileUploader
          id="video-picker"
          multiple
          accept="video/*"
          forwardedRef={addVideo}
          files={checkinMediaThumbnails}
          folder="sessions"
          filenameTemplate={() => {
            const userHash = hashids.encode(props.auth.user.id);
            const venueHash = hashids.encode(props.venue ? props.venue.id : new Date().getTime());
            return `${userHash}-${venueHash}-uuid`;
          }}
          uploading={(isUploading) => {
            setUploadingMedia(isUploading);
          }}
          onUploading={(uploadingFile, files) => {
            // console.log('Uploading file:');
            // console.log(uploadingFile);
            // console.log('Uploading files:');
            // console.log(files);
            if (files.length > 0) {
              // TODO Set so the media grid can use them - would be nice to show uploading animation
              setState((prevState) => ({
                ...prevState,
                selectedMedia: files,
              }));
              setCheckinMediaThumbnails(files);
            }
          }}
          onUploaded={(uploadedFile, files) => {
            // console.log('Uploaded files:');
            // console.log(files);
            if (files.length > 0) {
              setState((prevState) => ({
                ...prevState,
                selectedMedia: files,
              }));
              setCheckinMediaThumbnails(files);
            }
          }}
        />
        <div>Add To Checkin:</div>
        <Chip
          color="default"
          onClick={() => addMedia.current.click()}
          style={styles.chip}
          icon={<Icon name="image" />}
          label={<span style={{ marginLeft: 8 }}>Photo</span>}
        />
        <Chip
          color="default"
          onClick={() => addVideo.current.click()}
          style={styles.chip}
          icon={<Icon name="video" />}
          label={<span style={{ marginLeft: 8 }}>Video</span>}
        />
        {/* TODO Prompt to add rating, etc. If no, ask them again later via push notification or similar */}
        <Chip
          color="default"
          onClick={addCigar}
          style={styles.chip}
          icon={<Icon name="inbox" />}
          label={<span style={{ marginLeft: 8 }}>Cigar</span>}
        />
        <Chip
          color="default"
          onClick={addFriends}
          style={styles.chip}
          icon={<Icon name="users" />}
          label={<span style={{ marginLeft: 8 }}>Tag Friends</span>}
        />
      </div>
    </div>
  );

  const renderAdvancedRatingModal = () => (
    <ModalView
      open={showAdvancedRatingModal}
      showFrom="bottom"
      onClick={() => setShowAdvancedRatingModal(!showAdvancedRatingModal)}
      onClose={() => setShowAdvancedRatingModal(false)}
      rightButtons={[
        <MaterialButton aria-label="Next" onClick={() => setShowAdvancedRatingModal(false)}>Next</MaterialButton>,
      ]}
    >
      <div style={{ padding: 20 }}>
        {renderAdvancedRatingEntry()}
      </div>
    </ModalView>
  );

  const renderCigarSearch = () => (
    <CigarSearch
      onSelect={(cigar) => {
        console.log('Selected cigar:');
        console.log(cigar);
        setSelectedCigar(cigar);
        setShowCigarModal(false);
        setShowExtra(false);
      }}
    />
  );

  const renderCigarModal = () => (
    <ModalView
      title="Cigars"
      open={showCigarModal}
      showFrom="bottom"
      hideAppBarShadow
      onClick={() => setShowCigarModal(false)}
      onClose={() => setShowCigarModal(false)}
    >
      {renderCigarSearch()}
    </ModalView>
  );

  const renderCigar = () => (
    <div
      style={{
        display: 'flex',
        marginTop: 5,
      }}
    >
      <Avatar
        src={Cigar.getBandImage(selectedCigar)}
        style={{
          height: 20,
          width: 20,
          marginRight: 10,
          objectFit: 'contain',
        }}
      >
        {selectedCigar.name.charAt(0)}
      </Avatar>
      <div style={{ flex: 'auto' }}>
        {selectedCigar.full_name}
      </div>
    </div>
  );

  // FIXME Make this component re-usable
  const renderTaggedUsersModal = () => (
    <ModalView
      title="Select users"
      open={showTaggedUsersModal}
      showFrom="bottom"
      hideAppBarShadow
      onClick={() => setShowTaggedUsersModal(false)}
      onClose={() => setShowTaggedUsersModal(false)}
      rightButtons={[
        <MaterialButton
          aria-label="Next"
          onClick={() => {
            setTaggedUsers(selectedTaggedUsers);
            setShowTaggedUsersModal(false);
          }}
        >
          {'Next'}
        </MaterialButton>,
      ]}
    >
      <UserSearch
        multiSelect
        onSelect={(users) => {
          console.log('Selected tagged users:');
          console.log(users);
          setState((prevState) => ({
            ...prevState,
            selectedTaggedUsers: users, // This will always be an array
          }));
        }}
      />
    </ModalView>
  );

  const renderTaggedUsers = () => (
    <AvatarGroup>
      {taggedUsers.slice(0, 6).map((user) => (
        <Avatar src={user.image_url} alt={user.full_name} style={{ height: 30, width: 30 }} />
      ))}
      {taggedUsers.length > 6 && <Avatar style={{ height: 30, width: 30 }}><Icon name="more-horizontal" /></Avatar>}
    </AvatarGroup>
  );

  const renderAdvancedRatingEntry = () => (
    <Row style={{ marginBottom: 10 }}>
      <Col lg={3} className="text-center">
        <div className="text-label text-center">Selection</div>
        <Rating
          initialRating={checkinSelectionRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              checkinSelectionRating: value,
            }));
          }}
          emptySymbol={isMobile ? <EmptySquareStarLarge /> : <EmptySquareStar />}
          fullSymbol={isMobile ? <FilledSquareStarLarge /> : <FilledSquareStar />}
          fractions={2}
          style={{ color: 'rgb(214, 194, 144)', fontSize: isMobile ? 28 : 20 }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div className="text-center">Customer Service</div>
        <Rating
          initialRating={checkinServiceRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              checkinServiceRating: value,
            }));
          }}
          emptySymbol={isMobile ? <EmptySquareStarLarge /> : <EmptySquareStar />}
          fullSymbol={isMobile ? <FilledSquareStarLarge /> : <FilledSquareStar />}
          fractions={2}
          style={{ color: 'rgb(214, 194, 144)', fontSize: isMobile ? 28 : 20 }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div className="text-center">Atmosphere</div>
        <Rating
          initialRating={checkinAtmosphereRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              checkinAtmosphereRating: value,
            }));
          }}
          emptySymbol={isMobile ? <EmptySquareStarLarge /> : <EmptySquareStar />}
          fullSymbol={isMobile ? <FilledSquareStarLarge /> : <FilledSquareStar />}
          fractions={2}
          style={{ color: 'rgb(214, 194, 144)', fontSize: isMobile ? 28 : 20 }}
        />
      </Col>
      <Col lg={3} className="text-label text-center">
        <div className="text-center">Price</div>
        <Rating
          initialRating={checkinPriceRating}
          onChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              checkinPriceRating: value,
            }));
          }}
          emptySymbol={isMobile ? <EmptySquareStarLarge /> : <EmptySquareStar />}
          fullSymbol={isMobile ? <FilledSquareStarLarge /> : <FilledSquareStar />}
          fractions={2}
          style={{ color: 'rgb(214, 194, 144)', fontSize: isMobile ? 28 : 20 }}
        />
      </Col>
    </Row>
  );

  const renderMediaCropper = () => (
    <MediaCropper
      upload
      uploadFolder="checkins"
      imageFiles={checkinMediaThumbnails}
      file={selectedImageFile}
      src={selectedImageFile && selectedImageFile.src}
      filenameTemplate={() => {
        const userHash = hashids.encode(props.auth.user.id);
        const venueHash = hashids.encode(props.venue ? props.venue.id : new Date().getTime());
        return `${userHash}-${venueHash}-uuid`;
      }}
      open={showMediaCropper}
      thumbnailAspectRatio={0.8}
      toggle={() => setShowMediaCropper(!showMediaCropper)}
      onClose={() => setShowMediaCropper(false)}
      onSave={(croppedCanvas, files) => {
        // console.log('Cropping image...');
        setShowMediaCropper(false);
        console.log('Images after:');
        console.log(files);
        setCheckinMediaThumbnails(files);
      }}
      onUploading={(filename) => {
        setUploadingMedia(true);
      }}
      onUploaded={(filename, files) => {
        console.log('Uploaded file!');
        console.log(files);
        setCheckinMediaThumbnails(files);
        setState((prevState) => ({
          ...prevState,
          selectedMedia: files,
        }));
        setUploadingMedia(false);
      }}
    />
  );

  const handleSubmitSuccess = (updatedCheckin) => {
    setSubmittedId(updatedCheckin.id);
    saveDisabled = false;
    console.log('Media to submit:');
    console.log(selectedMedia);

    if (!localStorage.getItem('shouldShowRatingPrompt')) {
      localStorage.setItem('shouldShowRatingPrompt', 'true');
    }

    if (selectedMedia && selectedMedia.length) {
      const media = [];
      selectedMedia.forEach((mediaFile) => {
        if (mediaFile && mediaFile.media_url && !mediaFile.id && (!mediaFile.src || mediaFile.src.indexOf('base64') !== -1 || typeof mediaFile.original_file !== 'undefined')) {
          media.push({
            uuid: mediaFile.uuid,
            checkin_id: updatedCheckin.id,
            venue_id: props.venue.id,
            media_type: mediaFile.type,
            media_uri: mediaFile.media_url.replace(`https://${config.cdnUrl}`, '').replace(`https://${config.altCdnUrl}`, ''),
            media_url: mediaFile.media_url,
          });
        }
      });

      console.log('Formatted media:');
      console.log(media);

      try {
        VenueStore.update((s) => {
          updatedCheckin.media = updatedCheckin.media.concat(media);
          addOrMergeCheckin(updatedCheckin, s);
        });
      } catch (err) {
        ErrorLogger.captureException(err);
      }

      if (media.length > 0) {
        axios.post(`${config.apiEndPoint}/venues/checkins/${updatedCheckin.id}/media`, media).then((mediaRes) => {
          try {
            updatedCheckin.media = mediaRes.data;
            VenueStore.update((s) => {
              addOrMergeCheckin(updatedCheckin, s);
            });
          } catch (err) {
            ErrorLogger.captureException(err);
          }
          setSubmitting(false);
          // if (typeof props.onSave === 'function') {
          //   props.onSave(updatedCheckin);
          // }
          closeModal(false);
        }).catch((err) => {
          console.error(err);
          ErrorLogger.captureException(err);
          // TODO Show error message?
          setSubmitting(false);
        });
      }
    } else {
      try {
        VenueStore.update((s) => {
          addOrMergeCheckin(updatedCheckin, s);
        });
      } catch (err) {
        ErrorLogger.captureException(err);
      }
      setSubmitting(false);
      if (typeof props.onSave === 'function') {
        props.onSave(updatedCheckin);
      }
      closeModal(false);
    }
  };

  const handleSubmitError = (err) => {
    saveDisabled = false;
    setSubmitting(false);
    ModalDialog.show({
      title: 'Unable to save check in',
      message: 'An error occurred while saving your check in. If the problem persists, please contact Help & Support.',
      buttons: [{
        label: 'Get Help',
        onClick: () => {
          ModalDialog.close();
          // document.querySelector('#fc_frame').style.display = 'block';
          // if (window.fcWidget) {
          //   window.fcWidget.open({ name: 'Get Help' });
          // }
          if (window.tidioChatApi) {
            window.tidioChatApi.show();
            window.tidioChatApi.open();
          }
        },
      }, {
        label: 'Dismiss',
        onClick: () => {
          ModalDialog.close();
        },
      }],
    });
    ErrorLogger.captureException(err);
  };

  const saveCheckIn = () => {
    let existingCheckin = {};
    if (props.location && props.location.state && props.location.state.checkin) {
      existingCheckin = props.location.state.checkin;
    }
    const checkin = {
      id: existingCheckin.id || submittedId,
      venue_id: props.venue.id,
      user_id: props.auth && props.auth.user && props.auth.user.id,
      advance_rating: {
        venue_id: props.venue.id, // Do we need this here or in the db?
        rating: checkinAvgRating,
        selection: checkinSelectionRating,
        service: checkinServiceRating,
        atmosphere: checkinAtmosphereRating,
        price: checkinPriceRating,
      },
      tagged_users: taggedUsers,
      cigar_id: selectedCigar ? selectedCigar.id : null,
      comment,
    };

    console.log(checkin);

    if (!saveDisabled) {
      saveDisabled = true;
      setSubmitting(true);

      if (checkin.id) {
        console.log('Updating checkin...');
        axios.put(`${config.apiEndPoint}/venues/checkins/${checkin.id}`, checkin).then((response) => {
          handleSubmitSuccess(response.data);
        }).catch((err) => {
          handleSubmitError(err);
        });
        if (removeCheckinMedia.length > 0) {
          console.log('Submitting media to be unlinked from checkin...');
          axios.delete(`${config.apiEndPoint}/checkins/${checkin.id}/media`, {
            data: removeCheckinMedia,
          });
        }
      } else {
        axios.post(`${config.apiEndPoint}/venues/checkins`, checkin).then((response) => {
          handleSubmitSuccess(response.data);
        }).catch((err) => {
          console.log('Some error occurred');
          console.log(err);
          handleSubmitError(err);
        });
      }
    }
  };

  const toggleOpen = () => {
    const { toggle } = props;
    console.log('Toggling inside modal...');
    if (toggle && typeof toggle === 'function') {
      toggle();
    }
  };

  const resetAndClose = () => {
    const { onClose } = props;
    defaults.selectedMedia = [];
    defaults.checkinImageThumbnails = [];
    setCheckinMediaThumbnails([]);
    setState(defaults);
    initialLoad = true;
    console.log('Closing inside modal...');
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const closeModal = (prompt = true) => {
    if (prompt) {
      EditorDiscardConfirmation.show({
        title: 'Discard Checkin',
        message: 'Are you sure you want to discard this Checkin? Your changes will be lost.',
        onClick: resetAndClose,
      });
    } else {
      resetAndClose();
    }
  };

  const onSave = () => {
    setSubmitting(true);
    if (!uploadingMedia) {
      // Just submit
      saveCheckIn();
    } else {
      // Otherwise it will submit as soon as the last image is uploaded
      setNeedsSubmitted(true);
    }
  };

  if (isMobile) {
    return (
      <ModalView
        open={props.open}
        showFrom="right"
        onClick={toggleOpen}
        onClose={closeModal}
        rightButtons={[
          <MaterialButton aria-label="Save Check In" onClick={onSave}>{messages[language]?.save || 'Save'}</MaterialButton>,
        ]}
      >
        <div style={{ padding: 20 }}>
          {renderCheckInModalContent()}
        </div>
        {renderMediaCropper()}
        {renderAdvancedRatingModal()}
        {renderCigarModal()}
        {renderTaggedUsersModal()}
        {submitting && <LoadingIndicator overlay />}
      </ModalView>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={toggleOpen}
      style={{ maxWidth: 700 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={toggleOpen}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {renderCheckInModalContent()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>Close</Button>
        <Button color="primary" onClick={onSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

CheckinEditor.propTypes = {
  open: PropTypes.bool,
  venue: PropTypes.object,
  checkin: PropTypes.object,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(withRouter(CheckinEditor));
