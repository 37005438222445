import { fromJS } from 'immutable';
import { DELETE_CHECKIN } from './actions';

// const venueCheckinState = fromJS({
//   currentPage: 0,
//   totalPages: 999,
//   loading: false,
//   errorMessage: '',
//   checkins: List(),
// });

const deleteCheckinState = fromJS({
  loading: false,
  errorMessage: '',
});
export function deleteCheckinReducer(state = deleteCheckinState, action) {
  switch (action.type) {
    case DELETE_CHECKIN.FETCH: {
      return state.set('loading', true);
    }

    case DELETE_CHECKIN.SUCCESS: {
      // TODO Kick off updating feed, venue checkins, and user checkins
      return state.set('loading', false);
    }

    case DELETE_CHECKIN.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case DELETE_CHECKIN.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

export const initialState = fromJS({});
export default function listMappingReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_CHECKIN.FETCH:
    case DELETE_CHECKIN.SUCCESS:
    case DELETE_CHECKIN.FAILURE: {
      const selectedList = state.get(action.checkin.id);
      const updatedList = deleteCheckinReducer(selectedList, action);
      return state.set(action.checkin.id, updatedList);
    }
    default:
      return state;
  }
}
