import { createAsyncAction } from '../../utils/actionUtils';
import array from 'lodash/array';
import { CigarStore, VenueStore } from '../../stores';

export const LOAD_VENUE_CHECKINS = createAsyncAction('LOAD_VENUE_CHECKINS');
export const LOAD_USER_CHECKINS = createAsyncAction('LOAD_USER_CHECKINS');
export const SAVE_CHECKIN = createAsyncAction('SAVE_CHECKIN');
export const DELETE_CHECKIN = createAsyncAction('DELETE_CHECKIN');

export const addOrMergeCheckin = (checkin, state) => {
  const venueId = checkin.venue_id;
  console.log('Merging checkin:');
  console.log(checkin);
  const checkins = state.checkins[venueId];
  if (checkins) {
    // FIXME Better way to do this?
    for (let i = 0; i < checkins.length; i++) {
      if (parseInt(checkins[i].id) === parseInt(checkin.id)) {
        console.log('Found! Updating!');
        // eslint-disable-next-line no-param-reassign
        checkins[i] = checkin;
        console.log(checkins);
        return;
      }
    }
    console.log('Adding to beginning');
    checkins.unshift(checkin);
  } else {
    state.checkins[venueId] = [checkin];
  }
};

export const mergeCheckins = (checkins, state) => {
  if (checkins.length > 0) {
    const venueId = checkins[0].venue_id;
    const existingCheckins = state.checkins[venueId] || [];
    array.unionBy(existingCheckins, checkins, 'id');
  }
};

export const removeCheckin = (checkin, state) => {
  console.log('removeCheckin');
  console.log(checkin);
  const venueId = checkin.venue_id;
  const checkins = state.checkins[venueId] || [];
  for (let i = 0; i < checkins.length; i++) {
    if (parseInt(checkins[i].id) === parseInt(checkin.id)) {
      console.log('Found! Removing!');
      checkins.splice(i, 1);
      return;
    }
  }
};

export function requestVenueCheckins(venueId, page = 1, callback) {
  CigarStore.update((s) => { s.checkins.loading = true; });
  // TODO We can use runSaga() here - https://redux-saga.js.org/docs/api/index.html#external-api
  return {
    type: LOAD_VENUE_CHECKINS.REQUEST,
    venueId,
    page,
    callback,
  };
}

export function loadVenueCheckins(venueId, page = 1) {
  // TODO Axios call
  return {
    type: LOAD_VENUE_CHECKINS.FETCH,
    venueId,
    page,
  };
}

export function loadVenueCheckinsSuccess(venueId, paging, checkins, callback) {
  console.log('Got checkins for venue: ', venueId);
  console.log(checkins);
  CigarStore.update((s) => {
    s.checkins.loading = false;
    s.checkins[venueId] = checkins;
  });
  // return {
  //   type: LOAD_VENUE_CHECKINS.SUCCESS,
  //   venueId,
  //   paging,
  //   checkins,
  //   callback,
  // };
}

export function loadVenueCheckinsFail(venueId, error) {
  CigarStore.update((s) => { s.checkins.loading = true; });
  return {
    type: LOAD_VENUE_CHECKINS.FAILURE,
    venueId,
    error,
  };
}

export function saveCheckin(checkin) {
  return {
    type: SAVE_CHECKIN.REQUEST,
    checkin,
  };
}

export function savingUserCheckin(checkin) {
  // TODO Axios call or skip and merge into saveCheckin above
  return {
    type: SAVE_CHECKIN.FETCH,
    checkin,
  };
}

export function saveCheckinSuccess(checkin) {
  console.log('Saved! Updating checkins...');
  // TODO Do feed, too? Or simply ensure it gets pulled again?
  VenueStore.update((s) => {
    addOrMergeCheckin(checkin, s);
  });
  return {
    type: SAVE_CHECKIN.SUCCESS,
    checkin,
  };
}

export function saveCheckinFail(checkin, error) {
  return {
    type: SAVE_CHECKIN.FAILURE,
    checkin,
    error,
  };
}

export function deleteCheckin(checkin) {
  console.log('Requesting delete checkin...');
  return {
    type: DELETE_CHECKIN.REQUEST,
    checkin,
  };
}

export function deletingCheckin(checkin) {
  return {
    type: DELETE_CHECKIN.FETCH,
    checkin,
  };
}

export function deleteCheckinSuccess(checkin) {
  console.log('Deleted checkin, updating store...');
  console.log(checkin);
  VenueStore.update((s) => {
    removeCheckin(checkin, s);
  });
  return {
    type: DELETE_CHECKIN.SUCCESS,
    checkin,
  };
}

export function deleteCheckinFail(checkin, error) {
  return {
    type: DELETE_CHECKIN.FAILURE,
    checkin,
    error,
  };
}
