import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  DELETE_CHECKIN,
  deletingCheckin,
  deleteCheckinSuccess,
  deleteCheckinFail,
} from './actions';
import { config } from '../../settings';

export function* deleteCheckin(action) {
  const { checkin } = action;
  console.log('Deleting checkin...');
  yield put(deletingCheckin(checkin));
  try {
    const response = yield axios.delete(`${config.apiEndPoint}/venues/checkins/${checkin.id}`);
    // console.log('Checkin deletion response:');
    // console.log(response); // FIXME This returns a string
    // yield put(deleteCheckinSuccess(response.data));
    yield put(deleteCheckinSuccess(checkin));
  } catch (err) {
    console.log(err);
    yield put(deleteCheckinFail(checkin, err));
  }
}

export function* deleteCheckinWatcher() {
  yield takeLatest(DELETE_CHECKIN.REQUEST, deleteCheckin);
}

export default [
  deleteCheckinWatcher,
];
